/* eslint-disable max-len */
import _ from 'lodash';
// Warriors
import EarthGolem from '../assets/AcademiaDeck/EarthGolem.png';
import LivingWall from '../assets/AcademiaDeck/LivingWall.png';
import Gargoyle from '../assets/AcademiaDeck/Gargoyle.png';
import MageApprentice from '../assets/AcademiaDeck/MageApprentice.png';
import Rakshasa from '../assets/AcademiaDeck/Rakshasa.png';
import EnergyElemental from '../assets/AcademiaDeck/EnergyElemental.png';
import FireGolem from '../assets/AcademiaDeck/FireGolem.png';
import GremlinShooter from '../assets/AcademiaDeck/GremlinShooter.png';
import IceElemental from '../assets/AcademiaDeck/IceElemental.png';
import IceGiant from '../assets/AcademiaDeck/IceGiant.png';
import YoungGenie from '../assets/AcademiaDeck/YoungGenie.png';
// Spells
import ArcaneArmor from '../assets/AcademiaDeck/ArcaneArmor.png';
import Blizzard from '../assets/AcademiaDeck/Blizzard.png';
import CursedShackle from '../assets/AcademiaDeck/CursedShackle.png';
import Frostbite from '../assets/AcademiaDeck/Frostbite.png';
import FrostTrap from '../assets/AcademiaDeck/FrostTrap.png';
import IceBlock from '../assets/AcademiaDeck/IceBlock.png';
import IceSpikes from '../assets/AcademiaDeck/IceSpikes.png';
import IceWall from '../assets/AcademiaDeck/IceWall.png';
import IceWeapon from '../assets/AcademiaDeck/IceWeapon.png';
import Icing from '../assets/AcademiaDeck/Icing.png';
import InvisibilityCloak from '../assets/AcademiaDeck/InvisibilityCloak.png';
import SoulDrain from '../assets/AcademiaDeck/SoulDrain.png';
import Teleport from '../assets/AcademiaDeck/Teleport.png';
import TownPortal from '../assets/AcademiaDeck/TownPortal.png';
import Transformation from '../assets/AcademiaDeck/Transformation.png';
import Tsunami from '../assets/AcademiaDeck/Tsunami.png';
import Waterfall from '../assets/AcademiaDeck/Waterfall.png';
import IceArrow from '../assets/AcademiaDeck/IceArrow.png';
import IceArmor from '../assets/AcademiaDeck/IceArmor.png';

export const types = ['beforeturn', 'afterplay', 'protection', 'afterdeath'];

const academiaDeck = [
  {
    card: {
      name: 'Living wall', id: _.uniqueId(), type: 'warrior', subtype: 'fighter', power: 2, currentP: 2, health: 5, currentHP: 5, cost: 2, currentC: 2, description: 'LivingWall', faction: 'Academia', features: [{ name: 'immobile' }, { name: 'unarmed' }], attachments: [], img: LivingWall, status: 'hand', turn: 1,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Mage apprentice', id: _.uniqueId(), type: 'warrior', subtype: 'shooter', power: 2, currentP: 2, health: 4, currentHP: 4, cost: 3, currentC: 3, description: 'MageApprentice', faction: 'Academia', features: [{ name: 'massAttack' }], attachments: [], img: MageApprentice, status: 'hand', turn: 1,
    },
    qty: 2,
  },
  {
    card: {
      name: 'Gremlin shooter', id: _.uniqueId(), type: 'warrior', subtype: 'shooter', power: 2, currentP: 2, health: 3, currentHP: 3, cost: 2, currentC: 2, description: 'GremlinShooter', faction: 'Academia', features: [{ name: 'retaliation', value: 1, aim: ['shooter'] }], attachments: [], img: GremlinShooter, status: 'hand', turn: 1,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Ice element',
      id: _.uniqueId(),
      type: 'warrior',
      subtype: 'shooter',
      power: 2,
      currentP: 2,
      health: 4,
      currentHP: 4,
      cost: 3,
      currentC: 3,
      description: 'IceElement',
      faction: 'Academia',
      features: [{
        attach: false, type: 'bad', aim: ['enemyrowcell', 'warrior'], name: 'stun', condition: 'lastcall',
      }],
      attachments: [],
      img: IceElemental,
      status: 'hand',
      turn: 1,
    },
    qty: 2,
  },
  {
    card: {
      name: 'Earth golem',
      id: _.uniqueId(),
      type: 'warrior',
      subtype: 'fighter',
      power: 2,
      currentP: 2,
      health: 4,
      currentHP: 4,
      cost: 2,
      currentC: 2,
      description: 'EarthGolem',
      faction: 'Academia',
      features: [{
        attach: false, type: 'good', aim: ['adjacent', 'warrior'], name: 'heal', value: 1, condition: 'onplay',
      }],
      attachments: [],
      img: EarthGolem,
      status: 'hand',
      turn: 1,
    },
    qty: 2,
  },

  {
    card: {
      name: 'Gargoyle',
      id: _.uniqueId(),
      type: 'warrior',
      subtype: 'flyer',
      power: 2,
      currentP: 2,
      health: 4,
      currentHP: 4,
      cost: 2,
      currentC: 2,
      description: 'Gargoyle',
      faction: 'Academia',
      features: [{
        attach: false,
        aim: ['shooter', 'warrior'],
        type: 'good',
        name: 'protection',
        value: { type: 'number', val: 1 },
        condition: 'minPower',
        conditionValue: 2,
      }],
      attachments: [],
      img: Gargoyle,
      status: 'hand',
      turn: 1,
    },
    qty: 2,
  },
  {
    card: {
      name: 'Fire golem',
      id: _.uniqueId(),
      type: 'warrior',
      subtype: 'fighter',
      power: 3,
      currentP: 3,
      health: 6,
      currentHP: 6,
      cost: 4,
      currentC: 4,
      description: 'FireGolem',
      faction: 'Academia',
      features: [{
        attach: false, type: 'all', aim: ['row', 'warrior'], name: 'attack', condition: 'lastcall', value: 1,
      }],
      attachments: [],
      img: FireGolem,
      status: 'hand',
      turn: 1,
    },
    qty: 2,
  },
  {
    card: {
      name: 'Rakshasa',
      id: _.uniqueId(),
      type: 'warrior',
      subtype: 'fighter',
      power: 2,
      currentP: 2,
      health: 6,
      currentHP: 6,
      cost: 3,
      currentC: 3,
      description: 'Rakshasa',
      faction: 'Academia',
      features: [{
        attach: false, type: 'good', aim: ['warrior'], name: 'evade', condition: 'nextRowCell',
      }],
      attachments: [],
      img: Rakshasa,
      status: 'hand',
      turn: 1,
    },
    qty: 2,
  },
  {
    card: {
      name: 'Young genie',
      id: _.uniqueId(),
      type: 'warrior',
      subtype: 'flyer',
      power: 3,
      currentP: 3,
      health: 4,
      currentHP: 4,
      cost: 3,
      currentC: 3,
      description: 'YoungGenie',
      faction: 'Academia',
      features: [{
        name: 'invoke',
        aim: ['warrior'],
        type: 'good',
        value: {
          img: YoungGenie,
          name: 'fake',
          type: 'spell',
          subtype: 'instant',
          currentC: 0,
          description: 'YoungGenieCast',
          faction: 'Academia',
          place: '',
          features: [{
            attach: false, type: 'bad', aim: ['warrior'], name: 'moverow',
          }],
          status: 'hand',
        },
        condition: 'onplay',
      }],
      attachments: [],
      img: YoungGenie,
      status: 'hand',
      turn: 1,
    },
    qty: 3,
  },
  {
    card: {
      name: 'Energy elemental',
      id: _.uniqueId(),
      type: 'warrior',
      subtype: 'shooter',
      power: 2,
      currentP: 2,
      health: 5,
      currentHP: 5,
      cost: 4,
      currentC: 4,
      description: 'EnergyElemental',
      faction: 'Academia',
      features: [{
        name: 'attack', condition: 'onattack', type: 'bad', aim: ['nextrowcell', 'warrior'], value: 2, apply: 'attacked',
      }],
      attachments: [],
      img: EnergyElemental,
      status: 'hand',
      turn: 1,
    },
    qty: 2,
  },
  {
    card: {
      name: 'Ice giant',
      id: _.uniqueId(),
      type: 'warrior',
      subtype: 'fighter',
      power: 4,
      currentP: 4,
      health: 8,
      currentHP: 8,
      cost: 6,
      currentC: 6,
      description: 'IceGiant',
      faction: 'Academia',
      features: [{
        attach: false, type: 'bad', aim: ['row', 'warrior', 'fighter', 'shooter', 'flyer'], name: 'stun', condition: 'onplay',
      },
      {
        attach: false, type: 'good', aim: ['allyrowcell', 'warrior', 'fighter', 'shooter', 'flyer'], name: 'stun', condition: 'onplay',
      }],
      attachments: [],
      img: IceGiant,
      status: 'hand',
      turn: 1,
    },
    qty: 2,
  },
  {
    card: {
      name: 'Arcane armor',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'instant',
      cost: 1,
      currentC: 1,
      description: 'ArcaneArmor',
      faction: 'Academia',
      school: 'Shadow',
      place: 'postponed',
      features: [{
        attach: ['field', 'warrior', 'hero'], aim: ['warrior', 'fighter', 'shooter', 'flyer'], type: 'good', name: 'protection', value: { type: 'number', val: 100 }, charges: 1, condition: 'minPower', conditionValue: 3,
      }],
      img: ArcaneArmor,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Blizzard',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'temporary',
      cost: 2,
      currentC: 2,
      description: 'Blizzard',
      faction: 'Academia',
      school: 'Water',
      place: 'midSpell',
      features: [
        {
          attach: false, type: 'all', aim: ['row'], value: 2, name: 'attack',
        },
        {
          attach: false, type: 'all', aim: ['row', 'spell'], value: 2, name: 'attack', condition: 'lastcall',
        },
      ],
      img: Blizzard,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Cursed chackle',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'permanent',
      cost: 3,
      currentC: 3,
      description: 'CursedChackle',
      faction: 'Academia',
      school: 'Shadow',
      place: 'warrior',
      features: [
        {
          attach: ['warrior'],
          aim: ['warrior'],
          type: 'bad',
          condition: 'onattack',
          name: 'selfheroattack',
          value: 'power',
          apply: 'attacker',
        },
      ],
      img: CursedShackle,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Frostbite',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'permanent',
      cost: 2,
      currentC: 2,
      description: 'Frostbite',
      faction: 'Academia',
      school: 'Water',
      place: 'warrior',
      features: [
        {
          attach: ['warrior'],
          aim: ['warrior', 'fighter', 'shooter', 'flyer', 'hero'],
          type: 'bad',
          name: 'power',
          value: -2,
        },
      ],
      img: Frostbite,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Frost trap',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'instant',
      cost: 2,
      currentC: 2,
      description: 'FrostTrap',
      faction: 'Academia',
      school: 'Water',
      place: 'postponed',
      features: [{
        attach: ['field', 'warrior'], aim: ['warrior'], name: 'return', condition: 'onplay', type: 'bad', charges: 1,
      },
      {
        attach: ['field', 'warrior'], aim: ['warrior'], name: 'return', condition: 'onmove', type: 'bad', charges: 1,
      }],
      img: FrostTrap,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Ice block',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'temporary',
      cost: 3,
      currentC: 3,
      description: 'IceBlock',
      faction: 'Academia',
      school: 'Water',
      place: 'warrior',
      features: [
        {
          attach: ['hero'],
          aim: ['hero', 'fighter', 'shooter', 'flyer', 'warrior', 'spell'],
          type: 'good',
          name: 'protection',
          value: { type: 'number', val: 100 },
        },
      ],
      img: IceBlock,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Ice spikes',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'instant',
      cost: 3,
      currentC: 3,
      description: 'IceSpikes',
      faction: 'Academia',
      school: 'Water',
      place: '',
      features: [{
        attach: false, type: 'bad', name: 'attack', value: 2, aim: ['warrior', 'line'],
      }],
      img: IceSpikes,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Ice wall',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'temporary',
      cost: 1,
      currentC: 1,
      description: 'IceWall',
      faction: 'Academia',
      school: 'Water',
      place: 'midSpell',
      features: [
        {
          attach: ['row', 'warrior'],
          type: 'all',
          aim: ['warrior', 'fighter', 'shooter'],
          name: 'unarmed',
        }],
      img: IceWall,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Ice weapon',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'permanent',
      cost: 4,
      currentC: 4,
      description: 'IceWeapon',
      faction: 'Academia',
      school: 'Water',
      place: 'warrior',
      features: [{
        attach: ['warrior'],
        aim: ['warrior', 'fighter', 'shooter', 'flyer', 'hero'],
        type: 'good',
        name: 'power',
        value: 2,
      }],
      img: IceWeapon,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Icing',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'temporary',
      cost: 2,
      currentC: 2,
      description: 'Icing',
      faction: 'Academia',
      school: 'Water',
      place: 'midSpell',
      features: [
        {
          attach: ['row', 'warrior'],
          aim: ['fighter', 'shooter', 'flyer'],
          type: 'all',
          name: 'immobile',
        },
        {
          attach: ['row', 'warrior'],
          aim: ['fighter', 'shooter', 'flyer'],
          type: 'all',
          name: 'unarmed',
        }],
      img: Icing,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Invisibility cloak',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'temporary',
      cost: 1,
      currentC: 1,
      description: 'InvisibilityCloak',
      faction: 'Academia',
      school: 'Shadow',
      place: 'warrior',
      features: [
        {
          attach: ['warrior'],
          aim: ['warrior'],
          type: 'good',
          name: 'invisible',
        },
        {
          attach: ['warrior'], type: 'good', name: 'protection', value: { type: 'percent', val: 0.5 }, aim: ['spell'],
        },
      ],
      img: InvisibilityCloak,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Soul drain',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'instant',
      cost: 5,
      currentC: 5,
      description: 'SoulDrain',
      faction: 'Academia',
      school: 'Shadow',
      place: '',
      features: [{
        attach: false, type: 'bad', name: 'attack', value: 200, aim: ['warrior'],
      }],
      img: SoulDrain,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Teleport',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'instant',
      cost: 1,
      currentC: 1,
      description: 'Teleport',
      faction: 'Academia',
      school: 'Shadow',
      place: '',
      features: [{
        attach: ['warrior'], type: 'good', aim: ['warrior'], charges: 1, name: 'moving',
      }],
      img: Teleport,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Town portal',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'instant',
      cost: 3,
      currentC: 3,
      description: 'TownPortal',
      faction: 'Academia',
      school: 'Shadow',
      place: '',
      features: [{
        attach: false, type: 'bad', name: 'return', aim: ['warrior'],
      }],
      img: TownPortal,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Transformation',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'instant',
      cost: 1,
      currentC: 1,
      description: 'Transformation',
      faction: 'Academia',
      school: 'Shadow',
      place: '',
      features: [{
        attach: false, type: 'bad', name: 'stun', aim: 'warrior',
      }, {
        attach: false, type: 'bad', name: 'moverow', aim: 'warrior',
      }],
      img: Transformation,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Tsunami',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'instant',
      cost: 7,
      currentC: 7,
      description: 'Tsunami',
      faction: 'Academia',
      school: 'Water',
      place: '',
      features: [{
        attach: false, type: 'all', aim: ['field', 'warrior', 'fighter', 'shooter'], name: 'attack', value: 7, depend: 'postponed', dependValue: 8,
      }],
      img: Tsunami,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Waterfall',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'temporary',
      cost: 2,
      currentC: 2,
      description: 'Waterfall',
      faction: 'Academia',
      school: 'Water',
      place: 'bigSpell',
      features: [{
        attach: ['field', 'warrior'], aim: ['warrior', 'fighter', 'shooter', 'flyer'], type: 'all', name: 'unarmed', condition: 'maxPower', conditionValue: 2,
      }],
      img: Waterfall,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Ice arrow',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'instant',
      cost: 2,
      currentC: 2,
      description: 'IceArrow',
      faction: 'Academia',
      school: 'Water',
      place: '',
      features: [{
        attach: false, type: 'bad', aim: ['warrior'], name: 'attack', value: 2,
      }, {
        attach: false, type: 'bad', aim: ['warrior'], name: 'stun',
      }],
      img: IceArrow,
      status: 'hand',
    },
    qty: 1,
  },
  {
    card: {
      name: 'Ice armor',
      id: _.uniqueId(),
      type: 'spell',
      subtype: 'temporary',
      cost: 1,
      currentC: 1,
      description: 'IceArmor',
      faction: 'Academia',
      school: 'Water',
      place: 'warrior',
      features: [{
        attach: ['warrior', 'hero'],
        aim: ['warrior', 'hero', 'spell', 'fighter', 'shooter', 'flyer'],
        type: 'good',
        name: 'protection',
        value: { type: 'number', val: 1 },
      },
      {
        attach: ['warrior', 'hero'],
        aim: ['warrior', 'hero', 'spell', 'fighter', 'shooter', 'flyer'],
        type: 'good',
        name: 'retaliateProtect',
        value: { type: 'number', val: 1 },
      }],
      img: IceArmor,
      status: 'hand',
    },
    qty: 1,
  },
];

export default academiaDeck;
