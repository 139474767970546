export default {
  LivingWall: 'Живая стена',
  MageApprentice: 'Ученица мага',
  GremlinShooter: 'Гремлин стрелок',
  IceElement: 'Элемент льда',
  EarthGolem: 'Земляной голем',
  Gargoyle: 'Гаргулья',
  FireGolem: 'Огненный голем',
  Rakshasa: 'Ракшаса',
  YoungGenie: 'Молодой джин',
  EnergyElemental: 'Элемент энергии',
  IceGiant: 'Ледяной гигант',
  ArcaneArmor: 'Чародейская броня',
  Blizzard: 'Снежная буря',
  CursedChackle: 'Проклятые оковы',
  Frostbite: 'Обморожение',
  FrostTrap: 'Морозная ловушка',
  IceBlock: 'Ледяной блок',
  IceSpikes: 'Ледяные шипы',
  IceWall: 'Стена льда',
  IceWeapon: 'Ледяное оружие',
  Icing: 'Оледенение',
  InvisibilityCloak: 'Накидка невидимости',
  SoulDrain: 'Похищение души',
  Teleport: 'Телепорт',
  TownPortal: 'Портал в город',
  Transformation: 'Превращение в животное',
  Tsunami: 'Цунами',
  Waterfall: 'Водопад',
  IceArrow: 'Ледяная стрела',
  IceArmor: 'Ледяная броня',
  YoungGenieCast: 'Заклинание',
  NalaHero: 'Нала-Герой',
};
