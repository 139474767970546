export const menuTipsHeaders = {
  STARTTURN: 'СТАРТ ХОДА',
  STUN: 'ОГЛУШЕНИЕ',
  INVISIBILITY: 'НЕВИДИМОСТЬ',
  REACTION: 'РЕАКЦИЯ',
  EVASION: 'УКЛОНЕНИЕ',
  MASSIVEBLOW: 'МАССОВЫЙ УДАР Х',
  SHOCKWAVE: 'УДАРНАЯ ВОЛНА Х',
  MAGICSHIELD: 'МАГИЧЕСКИЙ ЩИТ',
  POISONATTACK: 'ЯДОВИТАЯ АТАКА Х',
  LASTCAST: 'ПОСЛЕДНИЙ ВЫЗОВ',
};

export const menuTipsText = {
  STARTTURN: 'Этот эффект применяется во время старта хода владельца карты',
  LASTCAST: 'Эффект применяется перед тем как карта с этим эффектом отправится в сток',
  STUN: 'Поворачивает ВОИНА в состояние оглушения',
  INVISIBILITY: 'Вражеские воины не могут атаковать этого воина в свой ход базовой атакой, но могут атаковать героя или других воинов сквозь этого воина',
  REACTION: 'Эта способность применяется в ответ на какое-то событие в игре, для этого карта со способностью должна быть отложенной',
  EVASION: 'Воин поглощает первый урон от атаки вражеского воина по нему во время хода оппонента, но получает оглушение и передвигается на соседнюю клетку на линии не нанося ответный удар (не сработает, если оглушен или не может передвинуться)',
  MASSIVEBLOW: 'При атаке в свой ход по воину, дополнительно отнимает Х здоровья у воинов в соседних клетках на линии рядом с целью',
  SHOCKWAVE: 'Вместо базовой атаки, может атаковать пустую клетку до которой достает, при этом отнимает Х здоровья у прилегающих к этой клетке вражеских воинов (не атакует себя, безответная атака)',
  MAGICSHIELD: 'Снижает урон от атакующих заклинаний по воину с щитом на 50% (снизит 3 урона из 5-ти)',
  POISONATTACK: 'При базовой атаке в свой ход по воину, накладывает Х заклинаний Яд на атакуемого воина (можно взять его из стока или кладбища, если нет в руке)',
};
