export const maxActionPoints = 9;

export const maxCardTurn = 2;

export const minCardTurn = 0;

export const startCardsNumber1 = 5;

export const startCardsNumber2 = 5;

export const startCards2AfterDraw = 7;
