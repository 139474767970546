import _ from 'lodash';
import BannerMan from '../assets/CastleDeck/Bannerman.png';
import Defender from '../assets/CastleDeck/Defender.png';
import ImperialGriffon from '../assets/CastleDeck/ImperialGriffon.png';
import ImperialShooter from '../assets/CastleDeck/ImperialShooter.png';
import KnightGuardian from '../assets/CastleDeck/KnightGuardian.png';
import MasterOfLight from '../assets/CastleDeck/MasterOfLight.png';
import MasterShooter from '../assets/CastleDeck/MasterShooter.png';
import TemplarChampion from '../assets/CastleDeck/TemplarChampion.png';
import ValorKnight from '../assets/CastleDeck/ValorKnight.png';
import WarriorOfLight from '../assets/CastleDeck/WarriorOfLight.png';
import YoungPriestess from '../assets/CastleDeck/YoungPriestess.png';
import RighteousFighter from '../assets/CastleDeck/RighteousFighter.png';

// SPELLS

import Сonciliation from '../assets/CastleDeck/Сonciliation.png';
import Bless from '../assets/CastleDeck/Bless.png';
import Enlightenment from '../assets/CastleDeck/Enlightenment.png';
import HealingLight from '../assets/CastleDeck/HealingLight.png';
import HeavenProtection from '../assets/CastleDeck/HeavenProtection.png';
import HeavenShock from '../assets/CastleDeck/HeavenShock.png';
import HolyLand from '../assets/CastleDeck/HolyLand.png';
import AttackAura from '../assets/CastleDeck/AttackAura.png';
import LastChance from '../assets/CastleDeck/LastChance.png';
import LightShield from '../assets/CastleDeck/LightShield.png';
import Resurrection from '../assets/CastleDeck/Resurrection.png';
import RetaliationHammer from '../assets/CastleDeck/RetaliationHammer.png';
import Retribution from '../assets/CastleDeck/Retribution.png';
import RighteousHammer from '../assets/CastleDeck/RighteousHammer.png';
import SaintWord from '../assets/CastleDeck/SaintWord.png';
import Swift from '../assets/CastleDeck/Swift.png';
import ThunderBlast from '../assets/CastleDeck/ThunderBlast.png';
import YouthFountain from '../assets/CastleDeck/YouthFountain.png';

const castleDeck = [
  {
    card: {
      name: 'Imperial Shooter', type: 'warrior', subtype: 'shooter', power: 1, currentP: 1, health: 2, currentHP: 2, cost: 1, currentC: 1, description: 'ImperialShooter', faction: 'Castle', id: _.uniqueId(), features: [{}], attachments: [], img: ImperialShooter, status: 'hand', turn: 1, disabled: false,
    },
    qty: 2,
  },
  {
    card: {
      name: 'Master Shooter', type: 'warrior', subtype: 'shooter', power: 2, currentP: 2, health: 4, currentHP: 4, cost: 3, currentC: 3, description: 'MasterShooter', faction: 'Castle', id: _.uniqueId(), features: [{ name: 'retaliation', value: 1, aim: ['warrior', 'fighter', 'shooter', 'flyer'] }], attachments: [], img: MasterShooter, status: 'hand', turn: 1, disabled: false,
    },
    qty: 3,
  },
  {
    card: {
      name: 'Young Priestess',
      type: 'warrior',
      subtype: 'shooter',
      power: 1,
      currentP: 1,
      health: 4,
      currentHP: 4,
      cost: 2,
      currentC: 2,
      description: 'YoungPriestess',
      faction: 'Castle',
      id: _.uniqueId(),
      features: [{
        name: 'heal', condition: 'onturnstart', aim: ['oneadjacent', 'warrior'], value: 1,
      }],
      attachments: [],
      img: YoungPriestess,
      status: 'hand',
      turn: 1,
      disabled: false,
    },
    qty: 3,
  },
  {
    card: {
      name: 'Warrior Of Light', type: 'warrior', subtype: 'fighter', power: 2, currentP: 2, health: 4, currentHP: 4, cost: 2, currentC: 2, description: 'WarriorOfLight', faction: 'Castle', id: _.uniqueId(), features: [{ name: 'swift' }], attachments: [], img: WarriorOfLight, status: 'hand', turn: 1, disabled: false,
    },
    qty: 2,
  },
  {
    card: {
      name: 'Valor Knight',
      type: 'warrior',
      subtype: 'fighter',
      power: 2,
      currentP: 2,
      health: 6,
      currentHP: 6,
      cost: 3,
      currentC: 3,
      description: 'ValorKnight',
      faction: 'Castle',
      id: _.uniqueId(),
      features: [{
        name: 'heal', condition: 'onattack', type: 'good', aim: ['nextcells', 'warrior'], value: 1,
      }],
      attachments: [],
      img: ValorKnight,
      status: 'hand',
      turn: 1,
      disabled: false,
    },
    qty: 2,
  },
  {
    card: {
      name: 'Bannerman',
      type: 'warrior',
      subtype: 'fighter',
      power: 2,
      currentP: 2,
      health: 5,
      currentHP: 5,
      cost: 3,
      currentC: 3,
      description: 'Bannerman',
      faction: 'Castle',
      id: _.uniqueId(),
      attachments: [],
      features: [{
        name: 'invoke',
        aim: ['warrior'],
        type: 'good',
        value: {
          img: BannerMan,
          name: 'fake',
          type: 'spell',
          subtype: 'instant',
          currentC: 0,
          description: 'BannermanCast',
          faction: 'Castle',
          place: '',
          features: [{
            attach: ['warrior'], aim: ['warrior', 'fighter', 'shooter', 'flyer', 'hero'], type: 'good', name: 'power', value: 2, charges: 1, id: _.uniqueId(),
          }],
          status: 'hand',
        },
        condition: 'onplay',
      }],
      img: BannerMan,
      status: 'hand',
      turn: 1,
      disabled: false,
    },
    qty: 2,
  },
  {
    card: {
      name: 'Defender',
      type: 'warrior',
      subtype: 'fighter',
      power: 2,
      currentP: 2,
      health: 5,
      currentHP: 5,
      cost: 3,
      currentC: 3,
      description: 'Defender',
      faction: 'Castle',
      id: _.uniqueId(),
      features: [{ name: 'protection', value: { type: 'percent', val: 0.5 }, aim: ['spell'] },
        {
          attach: ['row'], type: 'good', name: 'redirect', aim: ['warrior', 'fighter', 'shooter', 'flyer'],
        }],
      attachments: [],
      img: Defender,
      status: 'hand',
      turn: 1,
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Righeous Fighter', type: 'warrior', subtype: 'fighter', power: 3, currentP: 3, health: 4, currentHP: 4, cost: 3, currentC: 3, description: 'RigheousFighter', faction: 'Castle', id: _.uniqueId(), features: [{ name: 'power', value: 1, aim: ['hero'] }], attachments: [], img: RighteousFighter, status: 'hand', turn: 1, disabled: false,
    },
    qty: 2,
  },
  {
    card: {
      name: 'Knight Guardian',
      type: 'warrior',
      subtype: 'fighter',
      power: 2,
      currentP: 2,
      health: 8,
      currentHP: 8,
      cost: 4,
      currentC: 4,
      description: 'KnightGuardian',
      faction: 'Castle',
      id: _.uniqueId(),
      features: [{
        attach: ['nextcells'], type: 'good', name: 'redirect', aim: ['warrior', 'fighter', 'shooter', 'flyer'],
      }],
      attachments: [],
      img: KnightGuardian,
      status: 'hand',
      turn: 1,
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Imperial Griffon',
      type: 'warrior',
      subtype: 'flyer',
      power: 2,
      currentP: 2,
      health: 7,
      currentHP: 7,
      cost: 4,
      currentC: 4,
      description: 'ImperialGriffon',
      faction: 'Castle',
      id: _.uniqueId(),
      features: [{
        name: 'retaliation', value: 1, aim: ['warrior', 'fighter'],
      }],
      attachments: [],
      img: ImperialGriffon,
      status: 'hand',
      turn: 1,
      disabled: false,
    },
    qty: 2,
  },
  {
    card: {
      name: 'Templar Champion',
      type: 'warrior',
      subtype: 'fighter',
      power: 3,
      currentP: 3,
      health: 7,
      currentHP: 7,
      cost: 5,
      currentC: 5,
      description: 'TemplarChampion',
      faction: 'Castle',
      id: _.uniqueId(),
      features: [{
        name: 'invoke',
        aim: ['warrior'],
        type: 'good',
        value: {
          img: TemplarChampion,
          name: 'fake',
          type: 'spell',
          subtype: 'instant',
          currentC: 0,
          description: 'TemplarChampCast',
          faction: 'Castle',
          place: '',
          features: [{
            attach: false, aim: ['warrior'], type: 'good', name: 'heal', value: 3,
          }],
          status: 'hand',
        },
        condition: 'onplay',
      },
      {
        name: 'attack', condition: 'onattack', type: 'bad', aim: ['nextrowcell', 'warrior'], value: 2, apply: 'attacked',
      }],
      attachments: [],
      img: TemplarChampion,
      status: 'hand',
      turn: 1,
      disabled: false,
    },
    qty: 2,
  },
  {
    card: {
      name: 'Master Of Light',
      type: 'warrior',
      subtype: 'fighter',
      power: 4,
      currentP: 4,
      health: 9,
      currentHP: 9,
      cost: 7,
      currentC: 7,
      description: 'MasterOfLight',
      faction: 'Castle',
      id: _.uniqueId(),
      features: [{
        attach: false, type: 'bad', aim: ['row', 'warrior'], value: 2, name: 'attack', condition: 'insteadatk', cost: 0,
      }],
      attachments: [],
      img: MasterOfLight,
      status: 'hand',
      turn: 1,
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Thunder Blast',
      type: 'spell',
      subtype: 'instant',
      cost: 4,
      currentC: 4,
      description: 'ThunderBlast',
      faction: 'Castle',
      id: _.uniqueId(),
      place: '',
      features: [{
        attach: false, type: 'bad', aim: ['warrior', 'fighter', 'shooter', 'flyer'], name: 'attack', value: 3, depend: 'postponed', dependValue: 4,
      },
      {
        attach: false, type: 'all', aim: ['adjacent'], name: 'stun',
      }],
      img: ThunderBlast,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Swiftness',
      type: 'spell',
      subtype: 'permanent',
      cost: 2,
      currentC: 2,
      description: 'Swiftness',
      faction: 'Castle',
      id: _.uniqueId(),
      place: 'warrior',
      features: [{
        attach: ['warrior'],
        aim: ['warrior'],
        type: 'good',
        name: 'swift',
      }],
      img: Swift,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Resurrection',
      type: 'spell',
      subtype: 'instant',
      cost: 4,
      currentC: 4,
      description: 'Resurrection',
      faction: 'Castle',
      id: _.uniqueId(),
      place: 'grave',
      turn: 0,
      features: [{
        attach: ['grave'], type: 'good', aim: ['warrior'], name: 'ressurect', condition: 'insteadatk', cost: 4, resCost: 0,
      },
      { name: 'cantPostpone' }],
      img: Resurrection,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Retribution',
      type: 'spell',
      subtype: 'temporary',
      cost: 1,
      currentC: 1,
      description: 'Retribution',
      faction: 'Castle',
      id: _.uniqueId(),
      place: 'warrior',
      features: [{
        attach: ['warrior'],
        aim: ['warrior', 'fighter', 'shooter', 'flyer'],
        type: 'good',
        name: 'power',
        value: 1,
        depend: 'goodattachments',
        dependValue: 1,
        charges: 1,
      }],
      img: Retribution,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Attack Aura',
      type: 'spell',
      subtype: 'temporary',
      cost: 2,
      currentC: 2,
      description: 'AttackAura',
      faction: 'Castle',
      id: _.uniqueId(),
      place: 'warrior',
      features: [
        {
          attach: ['warrior'], type: 'good', aim: ['enemyrowcell', 'warrior', 'spell'], name: 'attack', condition: 'lastcall', value: 2,
        },
        {
          attach: false, type: 'good', aim: ['enemyrowcell', 'warrior'], name: 'attack', value: 2,
        },
      ],
      img: AttackAura,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Healing Light',
      type: 'spell',
      subtype: 'instant',
      cost: 1,
      currentC: 1,
      description: 'HealingLight',
      faction: 'Castle',
      id: _.uniqueId(),
      place: '',
      features: [{
        attach: false, aim: ['warrior'], type: 'good', name: 'heal', depend: 'postponed', value: 3, dependValue: 4,
      }],
      img: HealingLight,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Youth Fountain',
      type: 'spell',
      subtype: 'temporary',
      cost: 2,
      currentC: 2,
      description: 'YouthFountain',
      faction: 'Castle',
      id: _.uniqueId(),
      place: 'bigSpell',
      features: [{
        attach: ['field', 'warrior'], type: 'bad', name: 'enemyheroheal', aim: ['warrior'], condition: 'onplay', value: 2,
      },
      {
        attach: ['field', 'warrior'], type: 'bad', name: 'enemyheroheal', aim: ['warrior'], condition: 'onmove', value: 2,
      }],
      img: YouthFountain,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Enlightenment',
      type: 'spell',
      subtype: 'turn',
      cost: 3,
      currentC: 3,
      description: 'Enlightenment',
      faction: 'Castle',
      id: _.uniqueId(),
      place: 'bigSpell',
      features: [{
        attach: ['field', 'warrior'], type: 'good', aim: ['warrior', 'fighter', 'shooter', 'flyer', 'hero'], name: 'power', value: 1,
      }],
      img: Enlightenment,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Heaven Protection',
      type: 'spell',
      subtype: 'temporary',
      cost: 1,
      currentC: 1,
      description: 'HeavenProtection',
      faction: 'Castle',
      id: _.uniqueId(),
      place: 'warrior',
      features: [{
        attach: ['warrior'],
        aim: ['warrior', 'fighter', 'shooter', 'flyer'],
        type: 'good',
        name: 'protection',
        value: { type: 'number', val: 100 },
        charges: 1,
      }],
      img: HeavenProtection,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Bless',
      type: 'spell',
      subtype: 'permanent',
      cost: 2,
      currentC: 2,
      description: 'Bless',
      faction: 'Castle',
      id: _.uniqueId(),
      place: 'warrior',
      features: [{
        attach: ['warrior'],
        aim: ['warrior', 'fighter', 'shooter', 'flyer', 'hero'],
        type: 'good',
        name: 'power',
        value: 1,
      }],
      img: Bless,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Holy Land',
      type: 'spell',
      subtype: 'temporary',
      cost: 1,
      currentC: 1,
      description: 'HolyLand',
      faction: 'Castle',
      id: _.uniqueId(),
      place: 'midSpell',
      features: [{
        attach: ['row', 'warrior'], type: 'bad', aim: ['warrior', 'fighter', 'shooter', 'flyer'], name: 'attack', condition: 'onmove', value: 2,
      },
      {
        attach: ['row', 'warrior'], type: 'bad', aim: ['warrior', 'fighter', 'shooter', 'flyer'], name: 'attack', condition: 'onplay', value: 2,
      }],
      img: HolyLand,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Heaven Shock',
      type: 'spell',
      subtype: 'instant',
      cost: 2,
      currentC: 2,
      description: 'HeavenShock',
      faction: 'Castle',
      id: _.uniqueId(),
      place: '',
      features: [{
        attach: false, aim: ['warrior'], type: 'bad', name: 'attack', value: 2,
      },
      {
        attach: false, aim: ['hero'], type: 'good', name: 'heroheal', value: 2,
      },
      ],
      img: HeavenShock,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Saint Word',
      type: 'spell',
      subtype: 'instant',
      cost: 4,
      currentC: 4,
      description: 'SaintWord',
      faction: 'Castle',
      id: _.uniqueId(),
      place: '',
      features: [{
        attach: false, type: 'bad', aim: ['row', 'warrior', 'fighter', 'shooter', 'flyer'], name: 'attack', value: 2, depend: 'postponed', dependValue: 3,
      },
      {
        attach: false, type: 'bad', aim: ['randomnextrow', 'warrior', 'fighter', 'shooter', 'flyer'], name: 'attack', value: 2,
      },
      ],
      img: SaintWord,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Righteous Hammer',
      type: 'spell',
      subtype: 'instant',
      cost: 2,
      currentC: 2,
      description: 'RighteousHammer',
      faction: 'Castle',
      id: _.uniqueId(),
      place: '',
      features: [{
        attach: false, aim: ['warrior'], type: 'bad', name: 'attack', value: 3, depend: 'warriorsdiff', dependValue: 1,
      }],
      img: RighteousHammer,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Retaliation Hammer',
      type: 'spell',
      subtype: 'instant',
      cost: 2,
      currentC: 2,
      description: 'RetaliationHammer',
      faction: 'Castle',
      id: _.uniqueId(),
      place: '',
      features: [
        {
          attach: false, type: 'bad', aim: ['warrior'], name: 'attack', value: 2,
        },
        {
          attach: false, type: 'bad', aim: ['warrior'], name: 'moverow',
        },
      ],
      img: RetaliationHammer,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'LightShield',
      type: 'spell',
      subtype: 'permanent',
      cost: 1,
      currentC: 1,
      description: 'LightShield',
      faction: 'Castle',
      id: _.uniqueId(),
      place: 'warrior',
      features: [{
        attach: ['warrior'],
        aim: ['warrior'],
        type: 'good',
        name: 'health',
        value: 2,
      },
      {
        attach: false,
        aim: ['warrior'],
        type: 'good',
        name: 'health',
        value: 2,
      }],
      img: LightShield,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Last Chance',
      type: 'spell',
      subtype: 'instant',
      cost: 2,
      currentC: 2,
      description: 'LastChance',
      faction: 'Castle',
      id: _.uniqueId(),
      place: 'postponed',
      features: [{
        attach: ['field', 'warrior'], type: 'good', aim: ['warrior', 'fighter', 'shooter', 'flyer'], name: 'protection', condition: 'canDie', value: { type: 'immortal', val: 0 }, charges: 1,
      }],
      img: LastChance,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
  {
    card: {
      name: 'Conciliation',
      type: 'spell',
      subtype: 'temporary',
      cost: 5,
      currentC: 5,
      description: 'Conciliation',
      faction: 'Castle',
      id: _.uniqueId(),
      place: 'bigSpell',
      features: [{
        attach: ['field', 'warrior', 'hero'], type: 'good', name: 'protection', aim: ['warrior', 'spell', 'fighter', 'shooter', 'flyer'], value: { type: 'number', val: 100 },
      }],
      img: Сonciliation,
      status: 'hand',
      disabled: false,
    },
    qty: 1,
  },
];

export default castleDeck;
