export default {
  ImperialShooter: 'Imperial shooter',
  MasterShooter: 'Master shooter',
  YoungPriestess: 'Young priestess',
  WarriorOfLight: 'Warrior of light',
  ValorKnight: 'Valor knight',
  Bannerman: 'Bannerman',
  Defender: 'Defender',
  RigheousFighter: 'Righteous fighter',
  KnightGuardian: 'Knight guardian',
  ImperialGriffon: 'Imperial griffon',
  TemplarChampion: 'Templar champion',
  MasterOfLight: 'Master of light',
  ThunderBlast: 'Thunder blast',
  Swiftness: 'Swiftness',
  Resurrection: 'Resurrection',
  Retribution: 'Retribution',
  AttackAura: 'Attack aura',
  HealingLight: 'Healing light',
  YouthFountain: 'Youth fountain',
  Enlightenment: 'Enlightenment',
  HeavenProtection: 'Heaven protection',
  Bless: 'Bless',
  HolyLand: 'Holy land',
  HeavenShock: 'Heaven shock',
  SaintWord: 'Saint word',
  RighteousHammer: 'Righteous hammer',
  RetaliationHammer: 'Retaliation hammer',
  LightShield: 'Light shield',
  LastChance: 'Last chance',
  Conciliation: 'Conciliation',
  TemplarChampCast: 'Spell',
  BannermanCast: 'Spell',
  ZigfridHero: 'Zigfrid-Warlord',
  DummyCard: 'Training',
};
