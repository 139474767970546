const tips = [
  { h1: 'STARTTURN', id: 't1' },
  { h1: 'LASTCAST', id: 't2' },
  { h1: 'STUN', id: 't3' },
  { h1: 'INVISIBILITY', id: 't4' },
  { h1: 'REACTION', id: 't5' },
  { h1: 'EVASION', id: 't6' },
  { h1: 'MASSIVEBLOW', id: 't7' },
  { h1: 'SHOCKWAVE', id: 't8' },
  { h1: 'MAGICSHIELD', id: 't9' },
  { h1: 'POISONATTACK', id: 't10' },
];

export default tips;
